import React, { useEffect, Suspense } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { authRoutes, publicRoutes } from "./routes";
import Navbar from "../Navbar";
import Footer from "../Footer";
import BookingModal from "../Booking";
import { useActions } from "../../Store/hooks/useActions";
import Loader from "../Loader";
import { check } from "../../API/userAPI";

const AppRouter = (): JSX.Element => {
  const { setDimensions, setIsAuthTrue } = useActions();

  useEffect(() => {
    setDimensions();
    if (sessionStorage.getItem("token"))
      check().then(() => {
        setIsAuthTrue();
      });
  }, []);

  return (
    <BrowserRouter>
      <Navbar />
      <BookingModal />
      <Suspense fallback={<Loader />}>
        <section>
          <Routes>
            {publicRoutes.map(({ path, Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
            {authRoutes.map(({ path, Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </section>
      </Suspense>
      <Footer />
    </BrowserRouter>
  );
};

export default AppRouter;
