import * as WindowDimensions from "./WindowDimensions";
import * as ActiveTypeModel from "./ActiveTypeModel";
import * as GoodsActionCreators from "./goods";
import * as BookingActionCreators from "./booking";
import * as GalleryActionCreators from "./gallery";
import * as AuthsActionCreators from "./auth";

export default {
  ...WindowDimensions,
  ...ActiveTypeModel,
  ...GoodsActionCreators,
  ...BookingActionCreators,
  ...GalleryActionCreators,
  ...AuthsActionCreators,
};
