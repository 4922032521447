import { loft, karmen } from './specification';

export const universal = {
  model: `universal`,
  modelName: `Трансформер`,
  MainIMG: `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/universal%2Fmain%2F00.webp?alt=media&token=3c1a6a4a-0fb6-4339-9773-46d60d87cf4d`,
  ImgList: [
    `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/universal%2Fmain%2F00.webp?alt=media&token=3c1a6a4a-0fb6-4339-9773-46d60d87cf4d`,
    `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/universal%2Fmain%2F01.webp?alt=media&token=256da617-f12a-4179-85d8-d829ef3cc428`,
  ],
  specifications: {
    dimensions: `2500*1580`,
    bedDimensions: `1500*2100`,
    transformation: `Еврокнига (тик-так)`,
    filling: `Независимый пружинный блок / ППУ (пенополиуретан)`,
    frame: `Массив`,
  },
  types: [
    {
      typeName: `Cерия “ЛОФТ”`,
      title: loft.title,
      description: loft.description,
      imgList: [
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/universal%2Floft%2F00.webp?alt=media&token=d2a5357b-fbec-4798-b17b-d24e35a28f74`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/universal%2Floft%2F01.webp?alt=media&token=1cda1b44-41c4-4d3f-9e9f-8f0572286e62`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/universal%2Floft%2F02.webp?alt=media&token=91449193-9d8f-416c-a85d-4b84273ef524`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/universal%2Floft%2F03.webp?alt=media&token=216f0c1b-4e62-48d0-9f86-1bffd933eccf`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/universal%2Floft%2F04.webp?alt=media&token=f18fd45e-3480-4508-824e-fc77283745b5`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/universal%2Floft%2F05.webp?alt=media&token=589cf421-dec0-4941-8902-621a9cd9a181`,
      ],
    },
    {
      typeName: `Серия “КАРМЕН”`,
      title: karmen.title,
      description: karmen.description,
      imgList: [
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/universal%2Fkarmen%2F00.webp?alt=media&token=bb4fb309-6fee-4cfb-82d1-2b986a9a67a3`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/universal%2Fkarmen%2F01.webp?alt=media&token=1ebb607e-f910-4794-9e00-3c06d55727c6`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/universal%2Fkarmen%2F02.webp?alt=media&token=2a80868f-bd6c-4b90-8345-1c670c0d6fee`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/universal%2Fkarmen%2F03.webp?alt=media&token=eb205510-bd52-43e7-a5ce-f6a4e2750df4`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/universal%2Fkarmen%2F04.webp?alt=media&token=fca0c475-69e4-4178-9aa9-3460632dcbed`,
      ],
    },
  ],
};
