import React from "react";
import { BallTriangle } from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./Style.scss";

export default function LoaderElement() {
  return (
    <div className="loader">
      <h1 className="loader-title">Loading...</h1>
      <BallTriangle color="#00BFFF" height={80} width={80} />
    </div>
  );
}
