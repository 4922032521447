import { Dispatch } from "redux";
import {
  ActiveTypeModelActions,
  ActiveTypeModelActionTypes,
} from "../reducers//type/ActiveTypeModel";

export const setActiveTypeModel = (activeModel: string) => {
  return (dispatch: Dispatch<ActiveTypeModelActions>): void => {
    sessionStorage.setItem("activeModel", activeModel);
    dispatch({
      type: ActiveTypeModelActionTypes.SET_MODEL_TYPE,
      payload: { activeModel },
    });
  };
};
