import { $authHost, $host } from "./index";
import { IGallaryData } from "../globalTypes";

export const getAllGalleryImgs = async (): Promise<IGallaryData> => {
  let res: IGallaryData = { items: [], count: 0 };
  await $host.get(`api/gallery/all`).then((resp) => {
    if (resp.data.status) {
      return res;
    }
    res = resp.data;
  });
  return res;
};

export const fetchGalleryImgs = async (type = "", page = 1, limit = 6) => {
  let res: IGallaryData = { items: [], count: 0 };
  await $host
    .get("api/gallery/all", {
      params: {
        type,
        page,
        limit,
      },
    })
    .then((resp) => {
      if (resp.data.status) {
        return res;
      }
      res = resp.data;
    });
  return res;
};

export const createNewGalleryImg = async (
  formData: unknown
): Promise<unknown> => {
  try {
    const { data } = await $authHost.post("api/gallery", formData);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteGalleryImg = async (id: number): Promise<unknown> => {
  try {
    const { data } = await $authHost.delete(`api/gallery/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const dropGallery = async (): Promise<unknown> => {
  try {
    const { data } = await $authHost.get(`api/gallery/drop`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
