import React from "react";
import "./Footer.scss";

import FLogo from "./components/FLogo";
import Contacts from "./components/Contacts";
import SocialLinks from "./components/SocialLinks";

export default function index() {
  return (
    <div className="footer-container">
      <FLogo />
      <Contacts />
      <SocialLinks />
    </div>
  );
}
