import React from "react";
import { NavLink } from "react-router-dom";
import "./SidebarMenu.scss";
import { LiksData } from "../../../LinksData";

import { BsInstagram, BsInfoCircle, BsBook } from "react-icons/bs";
import { FaTelegram, FaViber, FaPhone, FaHome } from "react-icons/fa";
import { IoMdPhotos } from "react-icons/io";
import { useTypedSelector } from "../../../Store/hooks/useTypedSelector";

type IsideBarProps = {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function SideBarMenu(props: IsideBarProps) {
  const { isAuth } = useTypedSelector((store) => store.auth);
  return (
    <div
      className={
        props.isActive
          ? "sidebarmenu-container active"
          : "sidebarmenu-container"
      }
    >
      <div className="sidebar-content" onClick={() => props.setIsActive(false)}>
        <div className="sidebar-links-container">
          <NavLink to="/" className="sidebar-nav-link">
            <div className="nav-link-icon">
              <FaHome />
            </div>
            <div className="nav-link-title">Главная</div>
          </NavLink>

          <NavLink to="/product" className="sidebar-nav-link">
            <div className="nav-link-icon">
              <BsBook />
            </div>
            <div className="nav-link-title">Каталог</div>
          </NavLink>

          {/* <NavLink to="/catalog" className="sidebar-nav-link">
            <div className="nav-link-icon">
              <BsBook />
            </div>
            <div className="nav-link-title">Каталог</div>
          </NavLink> */}

          <NavLink to="/gallery" className="sidebar-nav-link">
            <div className="nav-link-icon">
              <IoMdPhotos />
            </div>
            <div className="nav-link-title">Галерея</div>
          </NavLink>

          <NavLink to="/contacts" className="sidebar-nav-link">
            <div className="nav-link-icon">
              <BsInfoCircle />
            </div>
            <div className="nav-link-title">Контакты</div>
          </NavLink>
          {isAuth ? (
            <NavLink to="/admin" className="sidebar-nav-link">
              <div className="nav-link-icon">
                <BsInfoCircle />
              </div>
              <div className="nav-link-title">Админка</div>
            </NavLink>
          ) : (
            ""
          )}
        </div>
        <div className="sidebar-footer-container">
          <div className="social-links-container">
            <a
              href={LiksData.instagram}
              target="_blank"
              rel="noreferrer"
              className="icon-social-link"
            >
              <BsInstagram />
            </a>
            <a
              href={LiksData.telegram}
              target="_blank"
              rel="noreferrer"
              className="icon-social-link"
            >
              <FaTelegram />
            </a>
            <a
              href={LiksData.viber}
              target="_blank"
              rel="noreferrer"
              className="icon-social-link"
            >
              <FaViber />
            </a>
            <a
              href={LiksData.phone}
              target="_blank"
              rel="noreferrer"
              className="icon-social-link"
            >
              <FaPhone />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
