import { Dispatch } from "redux";
import { GalleryAction, GalleryActionTypes } from "../reducers/type/gallery";
import { fetchGalleryImgs } from "../../API/galleryAPI";

export const fetchGallery = (type: string, page: number, limit?: number) => {
  return async (dispatch: Dispatch<GalleryAction>): Promise<void> => {
    try {
      dispatch({ type: GalleryActionTypes.FETCH_GALLERY });
      const response = await fetchGalleryImgs(type, page, limit);
      dispatch({
        type: GalleryActionTypes.FETCH_GALLERY_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GalleryActionTypes.FETCH_GALLERY_ERROR,
        payload: "An error occurred while loading data.",
      });
    }
  };
};
