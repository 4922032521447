import React, { useEffect, useState } from "react";
import { useTypedSelector } from "../../Store/hooks/useTypedSelector";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { IMaskInput } from "react-imask";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import { sendMessage } from "../../API/telegramBot";
import { useActions } from "../../Store/hooks/useActions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<JSX.Element>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
interface State {
  textmask: string;
  numberformat: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function TextMaskCustom(props, ref: any) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(+375) 00 000 00 00"
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: string) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

export default function index(): JSX.Element {
  const { isActive } = useTypedSelector((store) => store.booking);
  const { setBookingOff } = useActions();
  const [open, setOpen] = useState(isActive);
  const [isVisiableBtn, setVisibleBtn] = useState(true);

  const [name, setName] = useState("");

  const cheakValidData = () => {
    if (
      name &&
      values.textmask != "(+375) 00 000 00 00" &&
      values.textmask.length >= 18
    ) {
      setVisibleBtn(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setBookingOff();
  };

  const sendData = () => {
    sendMessage(name, values.textmask);
    handleClose();
  };

  const [values, setValues] = React.useState<State>({
    textmask: "00 000 00 00",
    numberformat: "1320",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    cheakValidData();
  };

  useEffect(() => {
    setOpen(isActive);
  }, [isActive]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Мы свяжемся с вами в ближайшее время
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Имя"
          type="email"
          fullWidth
          variant="standard"
          onChange={(event) => {
            setName(event.target.value);
          }}
        />

        <FormControl variant="standard">
          <InputLabel htmlFor="formatted-text-mask-input"></InputLabel>
          <Input
            value={values.textmask}
            onChange={handleChange}
            name="textmask"
            id="formatted-text-mask-input"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent={TextMaskCustom as any}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button disabled={isVisiableBtn} onClick={sendData}>
          Отправить
        </Button>
      </DialogActions>
    </Dialog>
  );
}
