export interface IDimensionsState {
  width: number;
  height: number;
}

export interface DimensionsActions {
  type: string;
  payload: IDimensionsState;
}

export enum DimensionsActionTypes {
  SET_DIMENSIONS = "SET_DIMENSIONS",
}
