import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import OrderBtn from '../OrederBtn';
import './Navbar.scss';
import { Fade as Hamburger } from 'hamburger-react';
import SidebarMenu from './components/SidebarMenu';
import { useTypedSelector } from '../../Store/hooks/useTypedSelector';

export default function index() {
  const [isOpen, setOpen] = useState(false);
  const { isAuth } = useTypedSelector((store) => store.auth);

  const setHidden = () => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }
  };

  useEffect(() => {
    setHidden();
  }, [isOpen]);

  return (
    <nav className='navbar-container'>
      <Link to='/' className='logo-container' onClick={() => setOpen(false)}>
        <div className='logo-title-1'>ООО</div>
        <div className='logo-title-2'>Марсела</div>
      </Link>
      <div className='nav-links-container'>
        <NavLink to='/' className='nav-link'>
          Главная
        </NavLink>

        {/* <NavLink to="/catalog" className="nav-link">
          Каталог
        </NavLink> */}

        <NavLink to='/product' className='nav-link'>
          Каталог
        </NavLink>

        {/* <NavLink to="/gallery" className="nav-link">
          Галерея
        </NavLink> */}

        <NavLink to='/contacts' className='nav-link'>
          Контакты
        </NavLink>
        {isAuth ? (
          <NavLink to='/admin' className='nav-link'>
            Админка
          </NavLink>
        ) : (
          ''
        )}
      </div>
      <OrderBtn />
      <div className='hamburger'>
        <Hamburger
          toggled={isOpen}
          toggle={setOpen}
          distance='lg'
          color='#ffad4c'
          rounded
          label='Show menu'
          hideOutline={false}
        />
      </div>

      <SidebarMenu isActive={isOpen} setIsActive={setOpen} />
    </nav>
  );
}
