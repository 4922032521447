import {
  IBookingState,
  BookingActions,
  BookingActionTypes,
} from "./type/booking";

const defaultState: IBookingState = {
  isActive: false,
};

export default function BookingReducer(
  state = defaultState,
  action: BookingActions
): IBookingState {
  switch (action.type) {
    case BookingActionTypes.SET_BOOKING_ON:
      return {
        ...state,
        isActive: true,
      };
    case BookingActionTypes.SET_BOOKING_OFF:
      return {
        ...state,
        isActive: false,
      };

    default:
      return state;
  }
}
