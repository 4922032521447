import React from "react";
import OrderBtn from "../../../OrederBtn";

export default function index() {
  return (
    <div className="footer-right-container">
      <div className="nav-btn-container">
        <OrderBtn />
      </div>
    </div>
  );
}
