import { loft, milan } from './specification';

export const accordion = {
  model: `accordion`,
  modelName: `Аккордеон`,
  MainIMG: `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/accordion%2Fmain%2F00.webp?alt=media&token=b46e5e58-7687-4ec4-8753-7d673486003b`,
  ImgList: [
    `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/accordion%2Fmain%2F00.webp?alt=media&token=b46e5e58-7687-4ec4-8753-7d673486003b`,
    `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/accordion%2Fmain%2F01.webp?alt=media&token=75219806-cea9-4a3f-8f95-3182a0e907a9`,
    `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/accordion%2Fmain%2F02.webp?alt=media&token=0c64b39a-0222-47c4-b32f-79c46c8e4b19`,
  ],
  specifications: {
    dimensions: `1900*970`,
    bedDimensions: `1500*1950`,
    transformation: `Аккордеон`,
    filling: `Независимый пружинный блок / ППУ (пенополиуретан)`,
    frame: `Массив`,
  },
  types: [
    {
      typeName: `Cерия “ЛОФТ”`,
      title: loft.title,
      description: loft.description,
      imgList: [
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/accordion%2Floft%2F00.webp?alt=media&token=a4a7e35e-2c55-4cc8-ad1b-80bb494310e0`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/accordion%2Floft%2F01.webp?alt=media&token=ba299c13-9d24-4331-8009-8daf82fbe14d`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/accordion%2Floft%2F02.webp?alt=media&token=ed14bb6e-6e97-4a8f-bb79-8c2c2a9707d5`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/accordion%2Floft%2F03.webp?alt=media&token=bf1aa5c4-5d0b-4522-be9d-6986245ff57c`,
      ],
    },

    {
      typeName: `Cерия “МИЛАН”`,
      title: milan.title,
      description: milan.description,
      imgList: [
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/accordion%2Fmilan%2F00.webp?alt=media&token=14325fb8-5de8-400b-80f9-205e4fe33597`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/accordion%2Fmilan%2F01.webp?alt=media&token=9a9bd73c-caa7-412b-80b7-d51a54f5c291`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/accordion%2Fmilan%2F02.webp?alt=media&token=b7dbf917-80e8-4b4e-a91d-85156a5d0412`,
      ],
    },
  ],
};
