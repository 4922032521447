import React from "react";
import "./OrderBtn.scss";
import {} from "../../Store/hooks/useTypedSelector";
import { useActions } from "../../Store/hooks/useActions";

export default function index() {
  const { setBookingOn } = useActions();

  const btnClick = () => {
    setBookingOn();
  };

  return (
    <div
      className="order-btn"
      onClick={() => {
        btnClick();
      }}
    >
      Оставить заявку
    </div>
  );
}
