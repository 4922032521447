import axios from "axios";

// const url = "http://localhost:5000/";
const url = "https://v2071439.hosted-by-vdsina.ru/";

const $host = axios.create({
  baseURL: url,
  headers: { "content-type": "application/json" },
});

const $authHost = axios.create({
  baseURL: url,
  headers: { "content-type": "application/json" },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const authInterceptor = (config: any) => {
  config.headers.authorization = `Bearer ${sessionStorage.getItem("token")}`;
  return config;
};

$authHost.interceptors.request.use(authInterceptor);

export { $host, $authHost };
