export interface IBookingState {
  isActive: boolean;
}

export interface BookingActions {
  type: string;
  // payload: IBookingState;
}

export enum BookingActionTypes {
  SET_BOOKING_ON = "SET_BOOKING_ON",
  SET_BOOKING_OFF = "SET_BOOKING_OFF",
}
