import { loft, chester, karmen } from './specification';

export const uglovoy = {
  model: `uglovoy`,
  modelName: `Угловой диван`,
  MainIMG: `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Fmain%2F00.webp?alt=media&token=5f070843-3944-4a99-af05-970fba5735e3`,
  ImgList: [
    `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Fmain%2F00.webp?alt=media&token=5f070843-3944-4a99-af05-970fba5735e3`,
    `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Fmain%2F01.webp?alt=media&token=16964c6e-b71b-48f1-ab2b-3d0507e25cae`,
    `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Fmain%2F02.webp?alt=media&token=17d28e01-086c-46af-a3a5-829cbc01cfe8`,
    `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Fmain%2F03.webp?alt=media&token=6e870f0d-bbc0-41e0-9716-25545c9e3c19`,
    `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Fmain%2F04.webp?alt=media&token=6b19cf6e-00d5-4be4-86c5-3fbaad548b73`,
  ],
  specifications: {
    dimensions: `2800*1680`,
    bedDimensions: `1600*2400`,
    transformation: `Еврокнига (тик-так)`,
    filling: `Независимый пружинный блок / ППУ (пенополиуретан)`,
    frame: `Массив`,
  },
  types: [
    {
      typeName: `Cерия “ЛОФТ”`,
      title: loft.title,
      description: loft.description,
      imgList: [
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Floft%2F00.webp?alt=media&token=6c5e0581-f47e-4999-a895-610e6f3653b7`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Floft%2F01.webp?alt=media&token=88a07f52-a7e5-457c-a4f2-256d0da60f15`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Floft%2F02.webp?alt=media&token=79bdefd2-23b4-4aa9-abb7-9d06cae9b9a4`,
      ],
    },

    {
      typeName: `Cерия “ЧЕСТЕР”`,
      title: chester.title,
      description: chester.description,
      imgList: [
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Fchester%2F00.webp?alt=media&token=49551f8e-f60b-4da0-8a18-83de38428490`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Fchester%2F01.webp?alt=media&token=992e9977-09e8-4550-82d5-bab32a4a427f`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Fchester%2F02.webp?alt=media&token=489c0262-7e59-47bb-9d10-a123937eab49`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Fchester%2F03.webp?alt=media&token=7deb1575-90c2-4b1f-9aa6-fe63cf5ab27d`,
      ],
    },
    {
      typeName: `Серия “КАРМЕН”`,
      title: karmen.title,
      description: karmen.description,
      imgList: [
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Fkarmen%2F00.webp?alt=media&token=a54d6792-b5d7-4ad5-9f65-e2a9b4ac4028`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Fkarmen%2F01.webp?alt=media&token=d8713dce-15cc-40dd-ae35-0a52c8261152`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Fkarmen%2F03.webp?alt=media&token=d27d05ca-78f9-4a70-9c19-1d1971ac4a4b`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Fkarmen%2F04.webp?alt=media&token=2f98e3b1-287b-452d-b53c-d2959fc17c7e`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/uglovoy%2Fkarmen%2F05.webp?alt=media&token=5a6ab6bb-f690-4648-a2e8-a2f5a9569b9a`,
      ],
    },
  ],
};
