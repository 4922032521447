import { IGallaryData } from "../../../globalTypes";

export interface IGalleryState {
  gallery: IGallaryData;
  count: number;
  loading: boolean;
  error: null | string;
}

export enum GalleryActionTypes {
  FETCH_GALLERY = "FETCH_GALLERY",
  FETCH_GALLERY_SUCCESS = "FETCH_GALLERY_SUCCESS",
  FETCH_GALLERY_ERROR = "FETCH_GALLERY_ERROR",
}

interface IFetchGallaryAction {
  type: GalleryActionTypes.FETCH_GALLERY;
}
interface IFetchGallerySuccessAction {
  type: GalleryActionTypes.FETCH_GALLERY_SUCCESS;
  payload: IGallaryData;
}
interface IFetchGelleryErrorAction {
  type: GalleryActionTypes.FETCH_GALLERY_ERROR;
  payload: string;
}

export type GalleryAction =
  | IFetchGallaryAction
  | IFetchGallerySuccessAction
  | IFetchGelleryErrorAction;
