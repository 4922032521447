import { Dispatch } from "redux";
import { BookingActions, BookingActionTypes } from "../reducers//type/booking";

export const setBookingOn = () => {
  return (dispatch: Dispatch<BookingActions>): void => {
    dispatch({
      type: BookingActionTypes.SET_BOOKING_ON,
    });
  };
};

export const setBookingOff = () => {
  return (dispatch: Dispatch<BookingActions>): void => {
    dispatch({
      type: BookingActionTypes.SET_BOOKING_OFF,
    });
  };
};
