import React from "react";
import { LiksData } from "../../../../LinksData";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";

export default function index() {
  return (
    <div className="footer-center-container">
      <div className="contacts-title">Наши контакты:</div>
      <div className="contacts-container">
        <div className="contacts-block">
          <a
            href={LiksData.phone}
            target="_blank"
            rel="noreferrer"
            className="contacts-item"
          >
            <div className="contacts-icon">
              <LocalPhoneIcon />
            </div>
            <div className="contacts-text">+375 33 678 56 14</div>
          </a>

          <a
            href={LiksData.instagram}
            target="_blank"
            rel="noreferrer"
            className="contacts-item"
          >
            <div className="contacts-icon">
              <InstagramIcon />
            </div>
            <div className="contacts-text">ooo_marsela</div>
          </a>
        </div>
        <div className="contacts-block">
          <a
            href="http://maps.google.com/?ie=UTF8&hq=&ll=53.894932,27.43307&z=16"
            target="_blank"
            rel="noreferrer"
            className="contacts-item"
          >
            <div className="contacts-icon">
              <LocationOnRoundedIcon />
            </div>
            <div className="contacts-text">
              г.Минск, ул Мазурова, д.1, 2 этаж
            </div>
          </a>

          <a
            href="mailto:marselamebel@gmail.com"
            target="_blank"
            rel="noreferrer"
            className="contacts-item"
          >
            <div className="contacts-icon">
              <MailOutlineRoundedIcon />
            </div>
            <div className="contacts-text">marselamebel@gmail.com</div>
          </a>
        </div>
      </div>
    </div>
  );
}
