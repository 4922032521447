import { $authHost, $host } from "./index";
import jwt_decode from "jwt-decode";
import { IToken } from "../globalTypes";

export const registration = async (formData: unknown): Promise<unknown> => {
  try {
    const { data } = await $host.post("api/user/registration", formData);
    if (data.token) {
      sessionStorage.setItem("token", data.token);
      const res: IToken = jwt_decode(data.token);
      return res;
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const login = async (
  email: string,
  password: string
): Promise<unknown> => {
  const { data } = await $host.post("api/user/login", {
    email,
    password,
  });
  const res = { error: false, data };

  if (data.token) {
    sessionStorage.setItem("token", data.token);
    return jwt_decode(data.token);
  } else {
    res.error = true;
    return res;
  }
};

export const deleteUser = async (id: number): Promise<unknown> => {
  try {
    const { data } = await $authHost.delete(`api/user/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const check = async (): Promise<unknown> => {
  const { data } = await $authHost.get("api/user/auth");
  sessionStorage.setItem("token", data.token);
  const res: IToken = jwt_decode(data.token);
  return res;
};
