import {
  IDimensionsState,
  DimensionsActions,
  DimensionsActionTypes,
} from "./type/WindowDimensions";

const defaultState: IDimensionsState = {
  width: 1000,
  height: 800,
};

export default function WindowDimensionsReducer(
  state = defaultState,
  action: DimensionsActions
): IDimensionsState {
  switch (action.type) {
    case DimensionsActionTypes.SET_DIMENSIONS:
      return {
        // ...state,
        width: action.payload.width,
        height: action.payload.height,
      };

    default:
      return state;
  }
}
