import { loft, chester, karmen } from './specification';

export const eurobook = {
  model: `eurobook`,
  modelName: `Еврокнига`,
  MainIMG: `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Fmain%2F03.webp?alt=media&token=04ccf8b5-b57f-4621-9475-5dc1c9742226`,
  ImgList: [
    `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Fmain%2F00.webp?alt=media&token=bcdcb20a-08c4-4dde-904e-2911ef0762c7`,
    `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Fmain%2F01.webp?alt=media&token=92f7d4b2-4604-4f47-ad4f-37e9a663db76`,
    `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Fmain%2F02.webp?alt=media&token=2c20273f-0ac3-40a8-9314-b129f0960848`,
    `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Fmain%2F03.webp?alt=media&token=04ccf8b5-b57f-4621-9475-5dc1c9742226`,
    `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Fmain%2F04.webp?alt=media&token=3508257d-2557-4355-83c3-9add54b9ac29`,
    `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Fmain%2F05.webp?alt=media&token=c46c6213-d011-4767-a989-80934966ef4b`,
  ],
  specifications: {
    dimensions: `2350*1100`,
    bedDimensions: `1500*2000`,
    transformation: `Еврокнига (тик-так)`,
    filling: `Независимый пружинный блок / ППУ (пенополиуретан)`,
    frame: `Массив`,
  },
  types: [
    {
      typeName: `Cерия “ЛОФТ”`,
      title: loft.title,
      description: loft.description,
      imgList: [
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Floft%2F00.webp?alt=media&token=f6955eb4-0c9d-4c0f-ab47-8c1194c0dc72`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Floft%2F01.webp?alt=media&token=e556cd13-4add-47e0-a996-aa95682c7b8b`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Floft%2F02.webp?alt=media&token=0e69f523-c364-4837-8f30-12610ddb7c2b`,
      ],
    },
    {
      typeName: `Серия “КАРМЕН”`,
      title: karmen.title,
      description: karmen.description,
      imgList: [
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Fkarmen%2F00.webp?alt=media&token=28e26ca0-27c3-4e2f-b6d4-d7e673793be7`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Fkarmen%2F01.webp?alt=media&token=ad86af31-4f55-4594-aeb8-f903f65b5e02`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Fkarmen%2F02.webp?alt=media&token=941fdef8-274f-477c-9c7b-2a4f8db3dc28`,
      ],
    },
    {
      typeName: `Cерия “ЧЕСТЕР”`,
      title: chester.title,
      description: chester.description,
      imgList: [
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Fchester%2F00.webp?alt=media&token=4a08c5f5-cb5a-4ac0-b7c4-13bbab2dba22`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Fchester%2F01.webp?alt=media&token=af354901-22a6-4d56-a38b-15580ab6f740`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Fchester%2F02.webp?alt=media&token=48c60df7-a629-481a-a081-5b2f1c72b5b4`,
        `https://firebasestorage.googleapis.com/v0/b/masela-by.appspot.com/o/eurobook%2Fchester%2F03.webp?alt=media&token=8f00a687-fa58-4e9d-a44a-541e375d6a77`,
      ],
    },
  ],
};
