const pnoneNumber = "375336785614";

export const LiksData = {
  phone: `tel:+${pnoneNumber}`,
  instagram: "https://www.instagram.com/ooo_marsela/",
  viber: `viber://chat?number=%2B${pnoneNumber}`,
  // telegram: "https://t.me/MarselaMebel",
  telegram: "https://t.me/marselaby",
  whatsApp: `https://wa.me/${pnoneNumber}`,
};
