// import { $host } from "./index";
import { IGoodsData } from "../globalTypes";

import { accordion } from "./FakeData/accordion";
import { universal } from "./FakeData/universal";
import { uglovoy } from "./FakeData/uglovoy";
import { eurobook } from "./FakeData/eurobook";

const goodsData: IGoodsData[] = [accordion, universal, uglovoy, eurobook];

export const getAllProduct = async (): Promise<IGoodsData[]> => {
  const res: IGoodsData[] = goodsData;
  return res;
};
