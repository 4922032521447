import {
  IGalleryState,
  GalleryActionTypes,
  GalleryAction,
} from "./type/gallery";

const defaultState: IGalleryState = {
  gallery: { items: [{ type: "", src: "", id: 0 }], count: 1 },
  count: 1,
  loading: false,
  error: null,
};

export default function gelleryReducer(
  state = defaultState,
  action: GalleryAction
): IGalleryState {
  switch (action.type) {
    case GalleryActionTypes.FETCH_GALLERY:
      return {
        loading: true,
        error: null,
        gallery: { items: [{ type: "", src: "", id: 0 }], count: 0 },
        count: 1,
      };
    case GalleryActionTypes.FETCH_GALLERY_SUCCESS:
      return {
        loading: false,
        error: null,
        gallery: action.payload,
        count: action.payload.count,
      };
    case GalleryActionTypes.FETCH_GALLERY_ERROR:
      return {
        loading: false,
        error: action.payload,
        gallery: { items: [{ type: "", src: "", id: 0 }], count: 0 },
        count: 1,
      };

    default:
      return state;
  }
}
