import React from 'react';

const HomePage = React.lazy(() => import('../../Pages/HomePage'));
const CatalogPage = React.lazy(() => import('../../Pages/Catalog/index'));
const ProductPage = React.lazy(() => import('../../Pages/ProductPage/index'));
// const GalleryPage = React.lazy(() => import("../../Pages/Gallery/index"));
const ContactsPage = React.lazy(() => import('../../Pages/Contacts/index'));
const AdminPanel = React.lazy(() => import('../../Pages/AdminPanel/index'));

interface IRoute {
  path: string;
  Component: React.LazyExoticComponent<() => JSX.Element>;
}

export const publicRoutes: IRoute[] = [
  {
    path: '/',
    Component: HomePage,
  },
  {
    path: '/product',
    Component: ProductPage,
  },
  {
    path: '/catalog',
    Component: CatalogPage,
  },
  // {
  //   path: "/gallery",
  //   Component: GalleryPage,
  // },
  {
    path: '/contacts',
    Component: ContactsPage,
  },
];

export const authRoutes: IRoute[] = [
  {
    path: '/admin',
    Component: AdminPanel,
  },
];
