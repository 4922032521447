export interface IActiveTypeModelState {
  activeModel: string;
}

export interface ActiveTypeModelActions {
  type: string;
  payload: IActiveTypeModelState;
}
export enum ActiveTypeModelActionTypes {
  SET_MODEL_TYPE = "SET_MODEL_TYPE",
}
