const token = "5176713932:AAEAxWC_HJH84avLXjmOcsV8bSoTs-K0kwE";
const chatId = "-751998373";

export const sendMessage = async (name: string, phoneNumber: string) => {
  const newNumber = "+" + phoneNumber.replace(/[^0-9]/g, "");
  const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(
    name + "   " + newNumber
  )}`;
  fetch(url).then((response) => response.json());
};

export const sendMessageB2B = async (
  name: string,
  phone: string,
  email: string,
  description: string
) => {
  const newNumber = "+" + phone.replace(/[^0-9]/g, "");

  const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(
    `B2B\n${allert()}\n${nameText(name)}\n${phoneNumberText(
      newNumber
    )}\n${emailText(email)}\n${directionText(description)}`
  )}&parse_mode=html`;
  fetch(url).then((response) => response.json());
};

function nameText(name: string) {
  return `<b>Имя: </b>${name}`;
}

function allert() {
  return `<b>@VladimirKrasnogir @PanStasyan</b>`;
}

function phoneNumberText(phoneNumber: string) {
  return `<b>Телефон: </b>${phoneNumber}`;
}

function emailText(email: string) {
  return `<b>Email: </b>${email}`;
}

function directionText(direction: string) {
  return `<b>Описание: </b>${direction}`;
}
