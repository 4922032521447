import React from "react";

export default function index() {
  return (
    <div className="footer-logo-container">
      <div className="footer-logo">
        <div className="footer-logo-title-1">ООО</div>
        <div className="footer-logo-title-2">Марсела</div>
      </div>
      <div className="footer-left-info">Собственное производство</div>
      <div className="footer-left-info">мягкой мебели</div>
    </div>
  );
}
