import { Dispatch } from "redux";
import {
  DimensionsActions,
  DimensionsActionTypes,
} from "../reducers//type/WindowDimensions";

export const setDimensions = () => {
  return (dispatch: Dispatch<DimensionsActions>): void => {
    const { innerWidth: width, innerHeight: height } = window;

    dispatch({
      type: DimensionsActionTypes.SET_DIMENSIONS,
      payload: { width, height },
    });
  };
};
