import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import WindowDimensionsReducer from "./reducers/WindowDimensionsReducer";
import ActiveTypeModelReducer from "./reducers/ActiveTypeModelReducer";
import goodsReducer from "./reducers/GoodsReducer";
import BookingReducer from "./reducers/BookingReducer";
import GalleryReducer from "./reducers/GalleryReducer";
import authReducer from "./reducers/AuthReducer";

const rootReducer = combineReducers({
  dimensions: WindowDimensionsReducer,
  model: ActiveTypeModelReducer,
  goods: goodsReducer,
  booking: BookingReducer,
  gallery: GalleryReducer,
  auth: authReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export type RootState = ReturnType<typeof rootReducer>;
