import { Dispatch } from "redux";
import { GoodsAction, GoodsActionTypes } from "../reducers/type/goods";
import { getAllProduct } from "../../API/goodsAPI";

export const fetchGoods = () => {
  return async (dispatch: Dispatch<GoodsAction>): Promise<void> => {
    try {
      dispatch({ type: GoodsActionTypes.FETCH_GOODS });
      const response = await getAllProduct();

      dispatch({
        type: GoodsActionTypes.FETCH_GOODS_SUCCESS,
        payload: response,
      });
      // setTimeout(() => {
      //   console.log("timeout in action-creator");
      //   dispatch({
      //     type: GoodsActionTypes.FETCH_GOODS_SUCCESS,
      //     payload: response,
      //   });
      // }, 1000);
    } catch (error) {
      dispatch({
        type: GoodsActionTypes.FETCH_GOODS_ERROR,
        payload: "An error occurred while loading data.",
      });
    }
  };
};
