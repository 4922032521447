import {
  IActiveTypeModelState,
  ActiveTypeModelActions,
  ActiveTypeModelActionTypes,
} from "./type/ActiveTypeModel";

const getActiveModel = sessionStorage.getItem("activeModel");

const defaultState: IActiveTypeModelState = {
  activeModel: getActiveModel ? getActiveModel : "eurobook",
};

export default function ActiveTypeModelReducer(
  state = defaultState,
  action: ActiveTypeModelActions
): IActiveTypeModelState {
  switch (action.type) {
    case ActiveTypeModelActionTypes.SET_MODEL_TYPE:
      return {
        activeModel: action.payload.activeModel,
      };

    default:
      return state;
  }
}
