export const loft = {
  title: "Скандинавский стиль",
  description:
    "Для всех любителей домашнего уюта, спокойствия и комфорта мы создали мягкую мебель в стиле Лофт. Она подходит как для загородных домов, так и для квартир с большим пространством, не имеющих внутренних перегородок, и создает неповторимое настроение в Вашей гостиной. Нарочитая строгость линий, геометрические формы, четкость пропорций диванов помогают создавать атмосферу свободы и непринужденности.",
};

export const chester = {
  title: "Дыхание старой Англии",
  description:
    "Серия мягкой мебели Честер собрала в себя сочетание английского стиля, высокого комфорта и функциональности. Элегантная форма, мягкая стеганая обивка и изящно скругленные подлокотники – этот идеально продуманный диван украсит любой интерьер и придаст помещению неповторимый английский колорит. Родившийся еще в викторианскую эпоху, стиль мягкой мебели Честерфилд – это мировая классика, элегантность дизайна в сочетании с комфортом и практическими преимуществами.",
};

export const karmen = {
  title: "Универсальное решение",
  description:
    "Мягкая мебель серии «Кармен» является универсальным решением, которое подойдет к интерьерам разных стилей. Диван поможет создать уютную обстановку, обеспечить комфортный отдых, но и подчеркнет современные тренды в оформлении интерьера. В основании дивана блок независимых пружин, что делает из гостевого дивана удобную кровать. Модель оснащена вместительной нишей для белья. Несомненными достоинствами данной модели являются: универсальный дизайн, компактные габариты.",
};

export const milan = {
  title: "Компактные размеры",
  description:
    "Данный вид мебели представляет собой несложную конструкцию с компактными габаритами. Надежный и прочный механизм трансформации «аккордеон» удобен для комнаты, в которой не так уж много места и позволяет быстро и просто получить спальное место. Механизм с повышенными эксплуатационными характеристиками – подходит для каждодневного использования. Диван «Милан» стильный и элегантный, отлично дополнит дизайн интерьера придав атмосферу уюта.",
};
